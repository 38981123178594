import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Row} from 'antd';
import {selectGlobal} from '../../../redux/selectors';
import {resetMessage} from '../../../redux/actions';
import {showErrorFlashMessage, showSuccessFlashMessage, showWarningFlashMessage} from '../../../utils';

const GlobalMessage = ({global, handleResetMessage}) => {
  const {showMessage, messageObj} = global;

  useEffect(() => {
    if (showMessage === false) {
      return;
    }

    if (messageObj.type === 'error') {
      showErrorFlashMessage(messageObj.text);
    } else if (messageObj.type === 'success') {
      showSuccessFlashMessage(messageObj.text);
    } else if (messageObj.type === 'warn') {
      showWarningFlashMessage(messageObj.text);
    }

    handleResetMessage();
  }, [messageObj, showMessage, handleResetMessage]);

  return (
    <Row />
  );
};

GlobalMessage.propTypes = {
  global: PropTypes.object.isRequired,
  handleResetMessage: PropTypes.func.isRequired,
};

const mapState = state => {
  const global = selectGlobal(state);
  return {global};
};

const actions = {
  handleResetMessage: resetMessage,
};

export default connect(mapState, actions)(GlobalMessage);
