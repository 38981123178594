import React, {Suspense, lazy} from 'react';
import {loginLoader} from '../../loader';
import {ROUTE_FORGOT_PASSWORD, ROUTE_LOGIN} from '../../RoutePathConstant';
import {Spin} from 'antd';

const Login = lazy(() =>
  import('../../../screens/Login')
    .then(module => ({default: module.Login})));

const ForgotPassword = lazy(() =>
  import('../../../screens/Login')
    .then(module => ({default: module.ForgotPassword})));

const LoginNavigation = [
  {
    path: ROUTE_LOGIN,
    element: (
      <Suspense fallback={<Spin />}>
        <Login />
      </Suspense>
    ),
    loader: loginLoader,
  },
  {
    path: ROUTE_FORGOT_PASSWORD,
    element: (
      <Suspense fallback={<Spin />}>
        <ForgotPassword />
      </Suspense>
    ),
    loader: loginLoader,
  },
];

export default LoginNavigation;
