import {createContext, useContext, useRef} from 'react';
import {proxy, useSnapshot} from 'valtio';
import {getFullTailwindConfig} from '@app/utils';
import * as PropTypes from 'prop-types';

// 1) CREATE A CONTEXT
const MainContext = createContext();

const MainProvider = ({children}) => {
  const state = useRef(
    proxy({
      tailwindConfig: getFullTailwindConfig(),
    }),
  ).current;

  return (
    // 2) PROVIDE VALUE TO CHILD COMPONENTS
    <MainContext.Provider value={state}>{children}</MainContext.Provider>
  );
};

MainProvider.propTypes = {children: PropTypes.any};

export default MainProvider;

export const useMainContext = () => {
  const mainState = useContext(MainContext);
  if (mainState === undefined) {
    throw new Error('MainContext was used outside of the MainProvider');
  }

  const mainSnapshot = useSnapshot(mainState);
  return {mainState, mainSnapshot};
};
