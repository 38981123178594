import {PRIMARY_100, PRIMARY_50, PRIMARY_900, PRIMARY_COLOR, SECONDARY_COLOR} from './GlobalColors';

const PrimaryTheme = {
  token: {
    colorPrimary: PRIMARY_COLOR,
    colorSecondary: SECONDARY_COLOR,
    fontFamily: 'Nunito',
  },
  components: {
    Table: {
      colorBgContainer: PRIMARY_50,
      colorFillAlter: PRIMARY_100,
      colorBorderSecondary: PRIMARY_900,
      borderRadiusLG: 0,
    },
  },
};

export default PrimaryTheme;
